// https://github.com/jesstech/postcss-rem-to-pixel/blob/master/lib/rem-unit-regex.js
// Not anything inside double quotes
// Not anything inside single quotes
// Not anything inside url()
// Any digit followed by rem
// !singlequotes|!doublequotes|!url()|remunit
const REM_REGEX = /"[^"]+"|'[^']+'|url\([^\)]+\)|(-?\d*\.?\d+)rem/g;

// Converts rem values to calc(var(--remMultiplier, 1) * value) format
// Eg. 1rem to calc(var(--remMultiplier, 1) * 1rem)
function getAdaptedRemValue(value) {
  return value.replace(REM_REGEX, remReplace);
}

function remReplace(match, $1) {
  if (!$1) return match;
  const remValue = parseFloat($1);

  return `calc(var(--remMultiplier, 1) * ${remValue}rem)`;
}

module.exports = getAdaptedRemValue;
