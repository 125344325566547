import type { PopupOwnProps } from '@mui/base/Unstable_Popup';

export const getPopoverFallbackPlacementByPlacement = (
  placement: PopupOwnProps['placement'],
): PopupOwnProps['placement'][] => {
  switch (placement) {
    case 'bottom': {
      return ['bottom-start', 'bottom-end', 'top', 'top-start', 'top-end', 'right', 'left'];
    }
    case 'top': {
      return ['top-start', 'top-end', 'bottom', 'bottom-start', 'bottom-end', 'right', 'left'];
    }
    case 'left': {
      return ['left-start', 'left-end', 'right', 'right-start', 'right-end', 'top', 'bottom'];
    }
    case 'right': {
      return ['right-start', 'right-end', 'left', 'left-start', 'left-end', 'top', 'bottom'];
    }
    case 'bottom-start': {
      return ['bottom', 'bottom-end', 'top', 'top-start', 'top-end', 'right', 'left'];
    }
    case 'bottom-end': {
      return ['bottom', 'bottom-start', 'top', 'top-start', 'top-end', 'right', 'left'];
    }
    case 'top-start': {
      return ['top', 'top-end', 'bottom', 'bottom-start', 'bottom-end', 'right', 'left'];
    }
    case 'top-end': {
      return ['top', 'top-start', 'bottom', 'bottom-start', 'bottom-end', 'right', 'left'];
    }
    case 'left-start': {
      return ['left', 'left-end', 'right', 'right-start', 'right-end', 'top', 'bottom'];
    }
    case 'left-end': {
      return ['left', 'left-start', 'right', 'right-start', 'right-end', 'top', 'bottom'];
    }
    case 'right-start': {
      return ['right', 'right-end', 'left', 'left-start', 'left-end', 'top', 'bottom'];
    }
    case 'right-end': {
      return ['right', 'right-start', 'left', 'left-start', 'left-end', 'top', 'bottom'];
    }
    default: {
      return ['bottom-start', 'bottom-end', 'top', 'top-start', 'top-end', 'right', 'left'];
    }
  }
};
