import React, { Children, cloneElement, forwardRef, isValidElement, useMemo } from 'react';
import useForkRef from '@mui/utils/useForkRef';
import { slowCn } from '../../lib/utils';
import { usePopoverContext } from './PopoverContext';
import type { PopoverButtonProps } from './types';

const getAnchorFromChildren = (children: React.ReactNode) => {
  const childArray = Children.toArray(children);

  if (childArray.length !== 1) {
    console.error(
      `[PopoverButton] Exactly 1 child must be passed to Popover, found ${childArray.length} children`,
    );
  }

  return childArray[0];
};

// Reference: https://github.com/uber/baseweb/blob/master/src/popover/popover.tsx#L295
const PopoverButton = forwardRef<HTMLElement, PopoverButtonProps>(
  function PopoverButton(props, forwardedRef) {
    const { children, classes, className, disabled, stopPropagation = true, ...rest } = props;

    const { onOpenChange, open, setAnchorEl } = usePopoverContext();
    const ref = useForkRef(forwardedRef, setAnchorEl);
    const anchor = getAnchorFromChildren(
      typeof children === 'function' ? children({ open }) : children,
    );

    const anchorProps = useMemo(() => {
      const anchorElement = anchor as null | React.ReactElement;

      if (!anchorElement) {
        return {};
      }

      const onClick = (event: React.MouseEvent) => {
        if (stopPropagation) {
          event.preventDefault();
          event.stopPropagation();
        }

        // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access -- this is a generic component, so unsafe access is necessary
        anchorElement.props?.onClick?.(event);
        onOpenChange(true);
      };

      const anchorClassName = slowCn(
        className,
        classes?.root,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-member-access -- this is a generic component, so unsafe access is necessary
        anchorElement.props?.className,
        disabled && classes?.disabled,
        open ? classes?.open : classes?.closed,
        { 'cursor-not-allowed': disabled, open },
      );

      return {
        'aria-expanded': Boolean(open),
        'aria-haspopup': true,
        className: anchorClassName,
        disabled,
        onClick: disabled ? undefined : onClick,
        ref,
      };
    }, [anchor, className, classes, disabled, onOpenChange, open, ref, stopPropagation]);

    if (!anchor) {
      return null;
    }

    if (typeof anchor === 'object' && isValidElement(anchor)) {
      return cloneElement(anchor, { ...rest, ...anchorProps });
    }

    return (
      <span {...rest} {...anchorProps}>
        {anchor}
      </span>
    );
  },
);

export type { PopoverButtonProps };
export default PopoverButton;
