import { createContext, useContext } from 'react';

export interface PopoverContextValue {
  anchorEl: HTMLElement | null;
  onClose: () => void;
  onOpen: () => void;
  onOpenChange: (open: boolean) => void;
  open: boolean;
  setAnchorEl: (anchorEl: HTMLElement | null) => void;
}

const PopoverContext = createContext<null | PopoverContextValue>(null);

const usePopoverContext = ({ showError } = { showError: true }) => {
  const context = useContext(PopoverContext);

  if (!context && showError) {
    throw new Error('PopoverContext must be used within Popover');
  }

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- when not null we are throwing an error
  return context!;
};

export { usePopoverContext };
export default PopoverContext;
