import { useCallback, useEffect, useRef, useState } from 'react';

interface Params<T> {
  controlledValue?: T;
  defaultValue?: T;
  onChange?: (val: T) => void;
}

function useControlledState<T>({
  controlledValue,
  defaultValue,
  onChange,
}: Params<T>): [T | undefined, (value: T) => void] {
  const [stateValue, setStateValue] = useState(controlledValue || defaultValue);

  const isControlledRef = useRef(controlledValue !== undefined);
  const isControlled = controlledValue !== undefined;
  useEffect(() => {
    const wasControlled = isControlledRef.current;
    if (wasControlled !== isControlled) {
      // eslint-disable-next-line no-console -- this is added changing from controlled to uncontrolled can lead to bugs
      console.warn(
        `WARN: A component changed from ${wasControlled ? 'controlled' : 'uncontrolled'} to ${isControlled ? 'controlled' : 'uncontrolled'}.`,
      );
    }
    isControlledRef.current = isControlled;
  }, [isControlled]);

  const currentValue = isControlled ? controlledValue : stateValue;
  const setValue = useCallback(
    (value: T) => {
      if (!isControlled) {
        setStateValue(value);
      }
      if (onChange) {
        if (!Object.is(currentValue, value)) {
          onChange(value);
        }
      }
    },
    [isControlled, currentValue, onChange],
  );

  return [currentValue, setValue];
}

export default useControlledState;
