import type { LegacyRef } from 'react';
import React, { forwardRef, useMemo } from 'react';
import type { PopupMiddleware, PopupOwnProps } from '@mui/base/Unstable_Popup';
import { Unstable_Popup as BasePopup } from '@mui/base/Unstable_Popup';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { clsx } from 'clsx';
import getAdaptedRemValue from '@unifyapps/style/helpers/getAdaptedRemValue';
import type { Placement } from '@floating-ui/react';
import { flip, offset as floatingUIOffset, shift } from '@floating-ui/react';
import { slowCn } from '../../../lib/utils';
import { usePopoverContext } from '../PopoverContext';
import { Box } from '../../Box';
import { usePopoverGlobalAnchorContext } from '../PopoverGlobalContainer';
import { useThemeOverrideContext } from '../../../providers/ThemeOverrideContext';
import type { PopoverContentProps } from './types';
import { getPopoverFallbackPlacementByPlacement } from './utils';

//floating-ui direction to fallback if it is overflowing from viewport
const getPopoverMiddleWare = ({
  offset,
  placement,
}: {
  offset: PopupOwnProps['offset'];
  placement: PopupOwnProps['placement'];
}) =>
  [
    flip({
      fallbackPlacements: getPopoverFallbackPlacementByPlacement(placement) as Placement[],
    }),
    // these two middlewares added by joy ui as we are overriding the default middleware
    //need to add these two middlewares to make it work as expected
    floatingUIOffset(offset),
    shift(),
  ] as PopupMiddleware[];

const getAdaptedRem = (value) => getAdaptedRemValue(`${value}rem`);

const getStyleWhenAdaptionDisabled = ({
  finalMinWidth,
  styleProp,
  useAnchorElWidth,
}: {
  styleProp: React.ComponentProps<typeof Box>['style'];
  finalMinWidth: number;
  useAnchorElWidth?: boolean;
}) => {
  if (useAnchorElWidth) {
    return {
      ...styleProp,
      minWidth: getAdaptedRem(finalMinWidth),
    };
  }
  return styleProp;
};

const getStyleWhenAdaptionEnabled = ({
  finalMinWidth,
  maxWidth,
}: {
  maxWidth?: number;
  finalMinWidth: number;
}) => {
  const style: React.ComponentProps<typeof Box>['style'] = {};
  if (maxWidth) style.maxWidth = getAdaptedRem(maxWidth);
  if (finalMinWidth) style.minWidth = getAdaptedRem(finalMinWidth);
  return style;
};

const PopoverChildrenContainerWrapper = forwardRef(function PopoverChildrenContainer(
  {
    className,
    style,
    children,
    ...rest
  }: {
    children: React.ReactNode;
    style: React.ComponentProps<typeof Box>['style'];
    className?: string;
  },
  ref: LegacyRef<HTMLDivElement> | undefined,
) {
  return (
    <Box
      className={slowCn(`border-secondary bg-primary rounded-lg border shadow-lg`, className)}
      ref={ref}
      style={style}
      {...rest}
    >
      {children}
    </Box>
  );
});

const popoverChildrenRenderer = ({
  children,
  disableClickAway,
  style,
  className,
  onClose,
}: {
  children: React.ReactNode;
  disableClickAway?: boolean;
  style: React.ComponentProps<typeof Box>['style'];
  className?: string;
  onClose: () => void;
}) => {
  if (disableClickAway) {
    return (
      <PopoverChildrenContainerWrapper className={className} style={style}>
        {children}
      </PopoverChildrenContainerWrapper>
    );
  }

  return (
    <ClickAwayListener onClickAway={onClose}>
      <PopoverChildrenContainerWrapper className={className} style={style}>
        {children}
      </PopoverChildrenContainerWrapper>
    </ClickAwayListener>
  );
};

function PopoverContent(props: React.PropsWithChildren<PopoverContentProps>) {
  const {
    popupClassName,
    children,
    style: styleProp = {},
    maxWidth,
    minWidth = 16,
    placement = 'bottom-start',
    className,
    offset = 12,
    anchorEl: anchorElProp,
    useAnchorElWidth,
    disableClickAway,
    useAnchorAsContainer,
    ignoreBoundary,
    disableStyleAdaptation,
    transform,
  } = props;
  const { anchorEl, onClose, open } = usePopoverContext({ showError: false });
  const finalAnchorEl = anchorElProp ?? anchorEl;
  const globalContainerEl = usePopoverGlobalAnchorContext();

  const finalMinWidth = useMemo(() => {
    return useAnchorElWidth && finalAnchorEl?.clientWidth
      ? finalAnchorEl.clientWidth / 16
      : minWidth;
  }, [finalAnchorEl?.clientWidth, minWidth, useAnchorElWidth]);

  const style = useMemo(() => {
    if (disableStyleAdaptation) {
      return getStyleWhenAdaptionDisabled({ styleProp, finalMinWidth, useAnchorElWidth });
    }

    return getStyleWhenAdaptionEnabled({
      maxWidth,
      finalMinWidth,
    });
  }, [disableStyleAdaptation, finalMinWidth, maxWidth, styleProp, useAnchorElWidth]);

  // used to override theme for popovers originating from the interface canvas
  const { themeOverrideClassName } = useThemeOverrideContext();
  const classNameWithThemeClasses = `${className} ${themeOverrideClassName}`;

  return (
    <BasePopup
      anchor={finalAnchorEl}
      className={clsx('z-popup', popupClassName)}
      container={globalContainerEl}
      disablePortal={useAnchorAsContainer}
      id="popover-content"
      middleware={
        ignoreBoundary
          ? undefined
          : getPopoverMiddleWare({
              offset,
              placement,
            })
      }
      offset={offset}
      open={open}
      placement={placement}
      strategy={useAnchorAsContainer ? 'fixed' : undefined}
      transform={transform}
      withTransition
    >
      {popoverChildrenRenderer({
        children,
        disableClickAway,
        style,
        className: classNameWithThemeClasses,
        onClose,
      })}
    </BasePopup>
  );
}

export type { PopoverContentProps };
export default PopoverContent;
