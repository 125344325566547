import React, { useMemo, useState } from 'react';
import useControlledState from '../../hooks/useControlledState';
import PopoverButton from './PopoverButton';
import PopoverContent from './PopoverContent';
import PopoverContext from './PopoverContext';
import type { PopoverProps } from './types';

function Popover(props: PopoverProps) {
  const {
    children,
    onOpenChange: onChange,
    open: controlledValue,
    initialValue = false,
    anchorEl: initialAnchorEl,
    onClose,
  } = props;
  const [isVisible, toggleVisibility] = useControlledState({
    controlledValue,
    defaultValue: initialValue,
    onChange,
  });

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(initialAnchorEl ?? null);
  const value = useMemo(
    () => ({
      anchorEl,
      onClose: () => {
        toggleVisibility(false);
        onClose?.();
      },
      onOpen: () => toggleVisibility(true),
      onOpenChange: toggleVisibility,
      open: Boolean(isVisible),
      setAnchorEl,
    }),
    [anchorEl, toggleVisibility, isVisible, onClose],
  );

  return (
    <PopoverContext.Provider value={value}>
      {typeof children === 'function' ? children(value) : children}
    </PopoverContext.Provider>
  );
}

Popover.displayName = 'Popover';

const PopoverNamespace = Object.assign(Popover, {
  Button: PopoverButton,
  Content: PopoverContent,
});

export default PopoverNamespace;
